//
//	SERVICE
//	####################################################################################################
//
	// Inside here (quick view):
  // - Header
  // - Service slider
  // - Section content

#service_page {

//
//	Header
//  --------------------
//


header.standard {
  background-position: center;
  background-attachment: initial;
}

&.air_conditioning header.standard {
  background-image: url('https://res.cloudinary.com/fridama/image/upload/c_scale,e_grayscale,w_1920/v1553599330/038.jpg');
}
&.electricity header.standard {
  background-image: url('https://res.cloudinary.com/fridama/image/upload/c_scale,e_grayscale,w_1920/v1553599336/020.jpg');
}
&.control header.standard {
  background-image: url('https://res.cloudinary.com/fridama/image/upload/c_scale,e_grayscale,w_1920/v1553599344/093.jpg');
  background-position: bottom;
}
&.energy_efficiency header.standard {
  background-image: url('https://res.cloudinary.com/fridama/image/upload/c_scale,e_grayscale,w_1920/v1553599342/089.jpg');
}

//
//	Service slider
//  --------------------
//

#service_slider_wrapper {
  @include relative-left;
  width: 100%;
  animation-delay: 1s;
  animation: fadeIn 2s;
  @include desktop-view {
    margin-top: -$double-margin-size;
  }
}

#service_slider {
  @include desktop-view {
    left: 50%;
    margin-left: -($desktop-container / 2);
  }
  @include tablet-view {
    @include relative-left;
    width: 100%;
    padding-left: $triple-margin-size;
    margin-top: $double-margin-size;
  }
  div.slick-slide {
    animation-delay: 1s;
    animation: slide 2s;
    background-color: white;
    margin-right: $margin-size;
    border-radius: 3px;
    &:focus {
      outline: 0;
    }
    img {
      border-radius: 3px;
      @include desktop-and-tablet-view {
        width: 534px;
      }
      @include mobile-view {
        width: 100vw;
      }
    }
  }
}

//
//	Section content
//  --------------------
//

section.content {
  p {
    @include relative-left;
    @include base-p-text-style();
    @include desktop-view {
      width: 75%;
    }
    @include tablet-and-mobile-view {
      width: 100%;
    }
    + ul {
      margin-top: 0;
    }
  }
  ul {
    @include relative-left;
    padding-left: $margin-size;
    border-left: 1px solid $highlight-color;
    margin-bottom: $double-margin-size;
    @include desktop-view {
      width: 75%;
    }
    @include tablet-and-mobile-view {
      width: 100%;
    }
    > li {
      @include base-p-text-style();
      margin-bottom: $half-margin-size;
      &::before {
        position: absolute;
        left: 0;
        content: "—";
        font-weight: bold;
        @include font-size(10);
        color: $highlight-color;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

}// service_page