//
//	CERTIFICATES
//	####################################################################################################
//
	// Inside here (quick view):
  // - Header
  // - Section content

#certificates_page {

//
//	Header
//  --------------------
//

header.standard {
  background-image: url('https://res.cloudinary.com/fridama/image/upload/c_scale,e_grayscale,w_1920/v1553599342/080.jpg');
  background-attachment: initial;
}

//
//	Section content
//  --------------------
//

section.content {
  div.bounded_content {
  }
  section {
    @include relative-left;
    margin-bottom: $double-margin-size;
    @include desktop-view {
      width: 75%;
      clear: both;
    }
    @include tablet-and-mobile-view {
      width: 100%;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    h2 {
      @include heading-3-text-style();
      margin-bottom: $margin-size;
      &::before {
        content: '';
        width: $margin-size;
        height: 2px;
        background-color: $fridama-blue-dark;
        position: absolute;
        left: -$margin-size -$margin-size;
        top: 15px;
      }
    }
    p.signature {
      font-style: italic;
    }
  }
  a.view_certs {
    display: inline-block;
    @include button-style-border();
    margin-top: $double-margin-size;
  }
}

}//certificates_page
