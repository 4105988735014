//
//  TYPOGRAPHY
//  ####################################################################################################
//
  // Inside here (quick view):
  // - Mixins
  // - Classes

//
//	Mixins
//  --------------------
//

@mixin heading-1-text-style($color:$countertype-text-color) {
  color: $color;
  font-weight: bold;
  @include desktop-view {
    @include font-size(56);
  }
  @include tablet-and-mobile-view {
    @include font-size(36);
  }
}

@mixin heading-2-text-style($color: $fridama-blue-dark) {
  color: $color;
  font-weight: 600;
  @include desktop-view {
    @include font-size(36);
  }
  @include tablet-and-mobile-view {
    @include font-size(28);
  }
}

@mixin heading-3-text-style($color: $fridama-blue-dark) {
  color: $color;
  @include font-size(24);
  @include line-height(32);
  font-weight: bold;
}

@mixin heading-4-text-style($color: $fridama-blue-dark) {
  color: $color;
  @include font-size(20);
  @include line-height(28);
  font-weight: bold;
}

@mixin base-p-text-style($color: $text-color) {
  color: $color;
  @include font-size(16);
  @include line-height(22);
}

@mixin small-p-text-style($color: $text-color) {
  color: $color;
  @include font-size(12);
}

@mixin blockquote-text-style($color: $fridama-blue-dark) {
  color: $color;
  @include font-size(22);
  @include line-height(28);
  font-style: italic;
  font-weight: bold;
}

@mixin link-style($color:$highlight-color) {
  @include font-size(14);
  text-transform: uppercase;
  color: $color;
  font-weight: bold;
  letter-spacing: .25px;
}

@mixin link-underline-hover-effect() {
  border-bottom: 2px solid transparent;
  transition: border-bottom .2s ease;
  &:hover {
    border-bottom: 2px solid currentColor;
    transition: border-bottom .2s ease;
  }
}

//
//	Classes
//  --------------------
//

.link {
  @include link-style();
}
.link_lowercase {
  @include link-style();
  font-size: inherit;
  text-transform: none;
}
