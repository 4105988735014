//
//	PAGE COMMONS
//	####################################################################################################
//
	// Inside here (quick view):
  // - General
  // - Nav desktop
  // - Nav mobile
  // - Header
  // - Section content
  // - Pagination
  // - Footer

//
//	General
//  --------------------
//

html {
  background-color: $light-gray-color;
}

body {
  @include relative-left;
  width: 100%;
  background-color: white;
  font-family: 'Roboto', sans-serif;
  text-rendering: geometricPrecision;
  overflow-x: hidden;
  animation: fadeIn 1s ease;
}

div.row {
  @include relative-left;
  width: 100%;
}

.bounded_content {
  @include relative-left;
  @include desktop-view {
    width: $desktop-container;
    @include advanced-horizontal-center($desktop-container);
  }
  @include tablet-view {
    width: 100%;
    padding-left: $triple-margin-size;
    padding-right: $triple-margin-size;
  }
  @include mobile-view {
    width: 100%;
    padding-left: $margin-size;
    padding-right: $margin-size;
  }
}

//
//	Nav desktop
//  --------------------
//

nav.desktop {
  width: 100%;
  display: flex;
  align-items: center;
  @include desktop-view {
    position: absolute;
    z-index: 1;
    height: $desktop-nav-height;
  }
  @include tablet-and-mobile-view {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: $mobile-nav-height;
  }
  div.bounded_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > a.logo {
      display: flex;
      img.logo {
        @include desktop-view {
          width: 200px;
        }
        @include tablet-and-mobile-view {
          width: 150px;
        }
      }
    }
    > a.open_nav_mobile {
      @include desktop-view {
        display: none;
      }
      @include tablet-and-mobile-view {
        @include font-size(16);
        @include square-box(40px);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $countertype-text-color;
        position: absolute;
        right: $half-margin-size;
      }
    }
    > ul {
      @include desktop-view {
        display: flex;
      }
      @include tablet-and-mobile-view {
        display: none;
      }
      > li {
        margin-right: $margin-size;
        &:last-child {
          margin-right: 0;
        }
        > a {
          @include link-style($countertype-text-color);
          @include link-underline-hover-effect();
        }
        .language {
          display: block;
          padding: ($quarter-margin-size / 2) $half-margin-size;
          margin-right: $half-margin-size;
          color: $countertype-text-color;
          @include font-size(10);
          text-transform: uppercase;
          font-weight: bold;
          border: 2px solid;
          border-radius: 30px;
        }
      }
    }
  }
}

ul#services_dropdown {
  width: auto !important;
  > li {
    min-height: initial;
    a {
      @include link-style();
      padding: $half-margin-size $margin-size;
    }
  }
}

//
//	Nav mobile
//  --------------------
//

nav.mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(to bottom, $highlight-color 0%, rgba($highlight-color, .93) 100%);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  > a.close_nav_mobile {
    position: absolute;
    top: $margin-size;
    right: $half-margin-size;
    color: $countertype-text-color;
    @include font-size(18);
    @include square-box(40px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > ul {
    @include relative-left;
    // width: 100%;
    > li {
      margin-bottom: $margin-size;
      > a {
        @include font-size(22);
        color: $countertype-text-color;
        &::before {
          position: absolute;
          left: -$margin-size;
          margin-top: 6px;
          content: "—";
          font-weight: bold;
          @include font-size(10);
        }
      }
    }
    > li.languages {
      display: flex;
      margin-top: $double-margin-size;
      > a {
        @include small-p-text-style($countertype-text-color);
        text-transform: uppercase;
        font-weight: bold;
        border: 2px solid;
        border-radius: 30px;
        padding: ($quarter-margin-size / 2) $half-margin-size;
        margin-right: $margin-size;
        opacity: .5;
        &.lang_en.en,
        &.lang_es.es {
          opacity: 1;
        }
        &::last-of-type {
          margin-right: 0;
        }
        &::before {
          content: '';
        }
      }
    }
  }
}

//
//	Header
//  --------------------
//

header.standard {
  @include relative-left;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  @include desktop-view {
    height: 300px;
    display: flex;
    align-items: center;
  }
  @include tablet-and-mobile-view {
    height: initial;
    padding-top: 80px + $margin-size;
    padding-bottom: $double-margin-size;
  }
  div.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($fridama-blue-dark, .85);
  }
}

header.standard ul.breadcrumb {
  display: flex;
  align-items: center;
  margin-bottom: $half-margin-size;
  > li {
    &::after {
      content: "/";
      color: $lighten-fridama-blue-dark;
      margin: 0 $quarter-margin-size;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    > a {
      @include link-style($lighten-fridama-blue-dark);
      @include link-underline-hover-effect();
    }
    > span {
      @include link-style($lighten-fridama-blue-dark);
    }
  }
}

header.standard h1 {
  @include heading-1-text-style();
  @include mobile-view {
    br {
      display: none;
    }
  }
}

//
//	Section content
//  --------------------
//

section.content {
  @include relative-left;
  width: 100%;
  @include desktop-view {
    padding: $double-margin-size 0 ($double-margin-size * 2);
  }
  @include tablet-view {
    padding: $double-margin-size 0;
  }
  @include mobile-view {
    padding: $double-margin-size 0;
  }
}

section.content p {
  @include base-p-text-style();
  margin-bottom: $margin-size;
  @include tablet-and-mobile-view {
    width: 100%;
  }
  &:last-of-type {
    // margin-bottom: 0;
  }
}
section.content ul:not(.select-dropdown) {
  padding-left: $margin-size;
  border-left: 1px solid $highlight-color;
  margin-bottom: $margin-size;
  > li {
    @include base-p-text-style();
    margin-bottom: $half-margin-size;
    &::before {
      position: absolute;
      left: 0;
      content: "—";
      font-weight: bold;
      @include font-size(10);
      color: $highlight-color;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.wysiwyg {
  img {
    @include relative-left;
    max-width: 100%;
  }
  p {
    @include relative-left;
    width: 100%;
    img + img {
      @include desktop-and-tablet-view {
        margin-right: $margin-size;
        &:last-of-type {
          margin-right: 0;
        }
      }
      @include mobile-view {
        @include relative-left;
        clear: both;
        margin-bottom: $margin-size;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  a {
    @extend .link_lowercase;
    @include link-underline-hover-effect();
  }
}

//
//	Pagination
//  --------------------
//

div.pagination {
  @include relative-left;
  display: flex;
  justify-content: space-between;
  @include desktop-view {
    width: 75%;
  }
  > a {
    display: inline-block;
    @include relative-left;
    margin-top: $margin-size;
    @include link-style();
    @include link-underline-hover-effect();
    &.newer {
      > i {
        margin-right: $half-margin-size;
      }
    }
    &.older {
      margin-left: auto;
      > i {
        margin-left: $half-margin-size;
      }
    }
  }
}

//
//	Footer
//  --------------------
//

footer {
  width: 100%;
  background-color: $light-gray-color;
  padding-top: $double-margin-size;
  @include desktop-view {
    @include relative-left;
    height: 400px;
  }
  @include tablet-and-mobile-view {
    @include relative-left;
    padding-bottom: $double-margin-size;
  }
  div.bounded_content {
    display: flex;
    @include desktop-view {
      justify-content: space-between;
    }
    @include tablet-and-mobile-view {
      flex-direction: column;
    }
    > div {
      display: flex;
      @include desktop-view {
        align-items: flex-start;
      }
      @include tablet-and-mobile-view {
        flex-direction: column;
      }
      div.company {
        @include desktop-view {
          flex-basis: 392px;
        }
        @include tablet-and-mobile-view {
          margin-bottom: $double-margin-size;
        }
        img {
          margin-bottom: $margin-size;
        }
        p {
          @include small-p-text-style($pale-text-color);
          margin-bottom: $margin-size;
          @include desktop-view {
            width: 196px;
          }
        }
        ul {
          display: flex;
          flex-direction: column;
          li {
            margin-bottom: $half-margin-size;
            a {
              @include link-style();
              @include font-size(12);
              @include link-underline-hover-effect();
            }
          }
        }
      }
      div.work_with_us {
        @include desktop-view {
          flex-basis: 196px;
        }
        @include tablet-and-mobile-view {
          margin-bottom: $double-margin-size;
        }
        p {
          &.title {
            font-weight: bold;
            margin-bottom: $half-margin-size;
            color: $fridama-blue-dark;
          }
          &.description {
            @include small-p-text-style($pale-text-color);
            margin-bottom: $margin-size;
          }
        }
        a {
          display: inline-block;
          @include button-style-border();
          @include desktop-view {
            @include font-size(12);
            padding: $quarter-margin-size $half-margin-size;
          }
        }
      }
      &.logos {
        display: flex;
        align-items: center;
        @include tablet-view {
          flex-direction: row;
        }
        > img {
          mix-blend-mode: darken;
          @include desktop-and-tablet-view {
            display: inline-block;
            margin-right: $margin-size;
            &:last-of-type {
              margin-right: 0;
            }
          }
          @include mobile-view {
            margin-bottom: $margin-size;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}