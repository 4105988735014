//
//  BUTTONS
//  ####################################################################################################
//
  // Inside here (quick view):
  // - Base
  // - Fill
  // - Border

//
//	Base
//  --------------------
//

@mixin button-style() {
  @include font-size(14);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: .25px;
  padding: $half-margin-size $margin-size;
  border-radius: 20px;
  letter-spacing: .25px;
  > i {
    margin-right: $half-margin-size;
  }
  @include mobile-view {
    width: 100%;
    text-align: center;
  }
}

//
//	Fill
//  --------------------
//

@mixin button-style-fill($color: $countertype-text-color, $bgcolor: $fridama-blue-light) {
  @include button-style();
  color: $color;
  background-color: $bgcolor;
  transition: background-color .3s ease;
  &:hover {
    background-color: darken($bgcolor, 3%);
    transition: background-color .3s ease;
  }
}

//
//	Border
//  --------------------
//

@mixin button-style-border($color:$highlight-color) {
  @include button-style();
  color: $color;
  border: 2px solid;
  background-color: transparent;
  transition: background-color .3s ease;
  &:hover {
    background-color: rgba($color, .1);
    transition: background-color .3s ease;
  }
}