//
//	HOMEPAGE
//	####################################################################################################
//
	// Inside here (quick view):
  // - Header
  // - Homepage section
  // - About us
  // - Our services
  // - Clients
  // - Contact
  // - Footer spacer

#index_page {

//
//	Header
//  --------------------
//

header.homepage {
  @include relative-left;
  width: 100%;
  overflow: hidden;
  background-image: url('https://res.cloudinary.com/fridama/image/upload/c_scale,e_grayscale,w_1920/v1553599342/080.jpg');
  background-size: cover;
  background-position: bottom center;
  background-attachment: fixed;
  @include desktop-view {
    height: $homepage-header-height;
    padding-top: $desktop-nav-height;
  }
  @include tablet-view {
    display: flex;
    align-items: center;
    padding-bottom: $double-margin-size;
  }
  @include mobile-view {
    height: $homepage-header-height;
    display: flex;
    align-items: center;
    padding-bottom: $double-margin-size;
  }
  div.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($fridama-blue-dark, .85);
  }
  h1 {
    @include desktop-view {
      margin-top: $triple-margin-size;
    }
    @include tablet-and-mobile-view {
      margin-top: $mobile-nav-height + $margin-size;
    }
    > span {
      display: block;
      &.small_header {
        @include heading-3-text-style($lighten-fridama-blue-dark);
        font-weight: 500;
        margin-bottom: $margin-size;
        @include mobile-view {
          br {
            display: none;
          }
        }
      }
      &.big_header {
        @include heading-1-text-style();
        @include mobile-view {
          br {
            display: none;
          }
        }
      }
    }
  }
  a {
    @include button-style-fill();
    @include relative-left;
    margin-top: $double-margin-size;
  }
}

//
//	Homepage section
//  --------------------
//

.homepage_section {
  @include relative-left;
  width: 100%;
  @include desktop-view {
    padding: $triple-margin-size 0;
  }
  @include tablet-and-mobile-view {
    padding: $double-margin-size 0;
  }
  h2 {
    @include heading-2-text-style();
    margin-bottom: $margin-size;
    &::before {
      content: '';
      width: $double-margin-size;
      height: 2px;
      background-color: $fridama-blue-dark;
      position: absolute;
      left: -$double-margin-size -$margin-size;
      top: 20px;
    }
  }
  p {
    @include base-p-text-style();
    margin-bottom: $half-margin-size;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

//
//	About us
//  --------------------
//

section#about_us {
  @include mobile-view {
    padding-bottom: 0;
  }
  div.text_content {
    @include desktop-view {
      width: 50%;
      padding-right: $double-margin-size;
    }
    @include tablet-and-mobile-view {
      margin-bottom: $double-margin-size;
    }
  }
}

section#about_us #about_us_slider {
  animation-delay: 1s;
  animation: fadeIn 2s;
  @include desktop-view {
    position: absolute;
    top: -$double-margin-size;
    left: 50%;
    width: 50vw;
    margin-bottom: 30px;
    padding-left: $double-margin-size;
  }
  @include tablet-view {
    @include relative-left;
    width: 100%;
    padding-left: $triple-margin-size;
  }
  @include mobile-view {
    @include relative-left;
    width: 100%;
  }
  div.slick-slide {
    animation-delay: 1s;
    animation: slide 2s;
    background-color: white;
    margin-right: $margin-size;
    border-radius: 3px;
    &:focus {
      outline: 0;
    }
    // &.slick-current img {
    //   opacity: 1;
    // }
    img {
      // opacity: .25;
      margin-bottom: $margin-size;
      border-radius: 3px;
      @include desktop-and-tablet-view {
        width: 534px;
      }
      @include mobile-view {
        width: 100vw;
      }
    }
  }
}

//
//	Our services
//  --------------------
//

#our_services {
  background-color: $light-gray-color;
  @include desktop-view {
    margin-top: $margin-size;
    padding-bottom: $double-margin-size;
  }
  @include tablet-and-mobile-view {
    padding-bottom: 0;
  }
  div.bounded_content {
    display: flex;
    @include tablet-and-mobile-view {
      flex-direction: column;
    }
    div.text_content {
      @include desktop-view {
        width: 50%;
        padding-right: $double-margin-size;
      }
    }
  }
  blockquote {
    @include desktop-view {
      width: 50%;
    }
    p {
      @include blockquote-text-style();
      @include desktop-view {
        padding: $margin-size;
        margin-left: $double-margin-size;
      }
      @include tablet-and-mobile-view {
        padding-top: $margin-size;
        padding-bottom: $margin-size;
        margin-top: $double-margin-size;
      }
    }
  }
}

#our_services div.materialize_tabs_wrapper {
  margin-top: $double-margin-size;
  margin-bottom: $margin-size;
}

#services_slider {
  @include relative-left;
  width: 100%;
  @include desktop-view {
    margin-top: $double-margin-size;
  }
  @include mobile-view {
    background-color: $highlight-color;
  }
  div.service {
    position: relative;
    display: flex;
    // opacity: .25;
    @include desktop-view {
      align-items: center;
      justify-content: center;
      width: $desktop-container;
      margin: 0;
    }
    @include tablet-view {
      align-items: center;
      justify-content: center;
      width: $tablet-container;
      margin: 0;
      margin-bottom: $margin-size;
    }
    @include mobile-view {
      flex-direction: column;
      width: 200px;
    }
    &:focus {
      outline: 0;
    }
    > img {
      border-radius: 3px;
      @include desktop-and-tablet-view {
        width: 80%;
        margin-bottom: $double-margin-size;
      }
      @include mobile-view {
        width: 100%;
      }
    }
    > div.text_content {
      opacity: 0;
      border-radius: 3px;
      background-color: rgba($fridama-blue-dark, .95);
      padding: $margin-size;
      transition: opacity .3s ease;
      @include desktop-and-tablet-view {
        position: absolute;
        width: calc(50% - #{$double-margin-size});
        right: 0;
        bottom: 0;
      }
      > h3 {
        @include heading-3-text-style($countertype-text-color);
        margin-bottom: $margin-size;
      }
      > p {
        @include base-p-text-style($countertype-pale-text-color);
        margin-bottom: $margin-size;
      }
      > a {
        @include link-style($lighten-fridama-blue-dark);
        @include link-underline-hover-effect();
      }
    }
    &.slick-current {
      opacity: 1;
      transition: opacity .3s ease;
      div.text_content {
        opacity: 1;
        transition: opacity .3s ease;
      }
    }
  }
}

//
//	Clients
//  --------------------
//

#clients {
  @include relative-left;
  width: 100%;
}
#clients_slider {
  @include relative-left;
  clear: both;
  width: 100%;
  margin-top: $double-margin-size;
  img {
    filter: grayscale(100%);
    margin-right: $double-margin-size;
  }
}

//
//	Contact
//  --------------------
//

#our_services + #contact {
  margin-top: $triple-margin-size;
  @include desktop-view {
    min-height: 320px;
    display: flex;
    align-items: center;
  }
  &::before {
    content: '';
    position: absolute;
    background-color: $light-gray-color;
    top: -$triple-margin-size;
    height: $triple-margin-size;
    width: 100%;
  }
}
#contact {
  z-index: 1;
  background-color: $fridama-blue-dark;
  margin-top: 50px;
  @include tablet-and-mobile-view {
    padding-top: 0;
  }
  img {
    border-radius: 3px;
    @include desktop-view {
      position: absolute;
      top: -50%;
      width: ($desktop-container / 2) + $margin-size;
      left: 50%;
      margin-left: -($desktop-container / 2);
      top: -25px;
    }
    @include tablet-and-mobile-view {
      width: 100%;
      margin-bottom: $double-margin-size;
    }
  }
  div.bounded_content div.text_content {
    @include desktop-view {
      width: 50%;
      float: right;
      padding-left: $double-margin-size * 2;
    }
    p {
      color: $countertype-text-color;
      &.highlight {
        @include heading-3-text-style($countertype-text-color);
        font-weight: normal;
        margin-bottom: $margin-size;
        @include mobile-view {
          br {
            display: none;
          }
        }
      }
      &.description {
        color: $countertype-pale-text-color;
      }
    }
    a.contact {
      display: inline-block;
      margin-top: $margin-size;
      @include button-style-fill();
    }
  }
}

//
//	Footer spacer
//  --------------------
//

#homepage_footer_spacer {
  @include relative-left;
  width: 100%;
  height: 60px;
  background-color: $light-gray-color;
  @include mobile-view {
    display: none;
  }
}

}//index_page