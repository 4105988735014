//
//	NEWS INDEX
//	####################################################################################################
//
	// Inside here (quick view):
  // - Header
  // - Section content

#news_index_page {

//
//	Header
//  --------------------
//

header.standard {
  // background-image: url('https://res.cloudinary.com/fridama/image/upload/c_scale,e_grayscale,w_1920/v1553599342/080.jpg');
  // background-position: bottom center;
}

//
//	Section content
//  --------------------
//

section.content {
  div.bounded_content {
  }
  article {
    @include relative-left;
    margin-bottom: $double-margin-size;
    padding-bottom: $double-margin-size;
    border-bottom: 1px dotted $highlight-color;
    @include desktop-view {
      width: 75%;
      clear: both;
    }
    @include tablet-and-mobile-view {
      width: 100%;
    }
    > h2 {
      margin-bottom: $half-margin-size;
      > a {
        @include heading-3-text-style();
        @include link-underline-hover-effect();
        br {
          display: none;
        }
      }
      &::before {
        content: '';
        width: $margin-size;
        height: 2px;
        background-color: $fridama-blue-dark;
        position: absolute;
        left: -$margin-size -$margin-size;
        top: 15px;
      }
    }
    > p.news_date {
      @include small-p-text-style($highlight-color);
      font-weight: bold;
    }
    a.read_more {
      display: inline-block;
      @include relative-left;
      margin-top: $margin-size;
      @include link-style();
      @include link-underline-hover-effect();
      > i {
        margin-left: $half-margin-size;
      }
    }
  }
}

}//certificates_page
