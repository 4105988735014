//
//	CONTACT
//	####################################################################################################
//
	// Inside here (quick view):
  // - Header
  // - Tabs
  // - Section content
  // - Form wrapper
  // - Map wrapper

#contact_page {

//
//	Header
//  --------------------
//

header.standard {
  background-image: url('https://res.cloudinary.com/fridama/image/upload/c_scale,e_grayscale,w_1920/v1553599344/105.jpg');
  background-position: center;
  background-attachment: initial;
}

//
//	Tabs
//  --------------------
//

div.tabs_wrapper {
  @include relative-left;
  width: 100%;
}

//
//	Section content
//  --------------------
//

section.content {
  > div.bounded_content {
    display: flex;
    @include desktop-view {
      justify-content: space-between;
    }
    @include tablet-and-mobile-view {
      flex-direction: column;
    }
  }
}

// Form wrapper

section.content div.form_wrapper {
  @include desktop-view {
    width: calc(60% - #{$margin-size});
    padding-right: $margin-size;
  }
  @include tablet-and-mobile-view {
    order: 2;
  }
  > p.intro {
    margin-bottom: $double-margin-size;
  }
  > iframe {
    width: 100%;
  }
}

//
//	Map wrapper
//  --------------------
//

section.content div.map_wrapper {
  @include desktop-view {
    width: calc(30% - #{$margin-size});
  }
  @include tablet-and-mobile-view {
    order: 1;
    margin-bottom: $triple-margin-size;
  }
  iframe {
    width: 100% !important;
    margin-bottom: $margin-size;
  }
  p.delegation {
    font-weight: bold;
    color: $highlight-color;
  }
  p.address {
    @include relative-left;
    width: 100%;
    margin-bottom: $margin-size;
    color: $highlight-color;
    @include font-size(14);
  }
  a.phone {
    display: block;
    margin-bottom: $quarter-margin-size;
    color: $highlight-color;
    @include font-size(14);
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

}//contact_page
