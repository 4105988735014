//
//	NEWS
//	####################################################################################################
//
	// Inside here (quick view):
  // - Header
  // - Section content

#error_page {

//
//	Header
//  --------------------
//

header.standard {
  div.overlay {
    background-color: $medium-gray-color;
  }
}

//
//	Section content
//  --------------------
//

section.content {
  a.back {
    @include relative-left;
    margin-top: $double-margin-size;
    clear: both;
    @include link-style();
    > i {
      margin-right: $half-margin-size;
    }
  }
}

}//error_page
