//
//	WORK WITH US
//	####################################################################################################
//
	// Inside here (quick view):
  // - Header
  // - Section content

#work_with_us_page {

//
//	Header
//  --------------------
//

header.standard {
  background-image: url('https://res.cloudinary.com/fridama/image/upload/c_scale,e_grayscale,w_1920/v1553599342/080.jpg');
  background-attachment: initial;
}

//
//	Work with us slider
//  --------------------
//

#work_with_us_slider_wrapper {
  @include relative-left;
  width: 100%;
  animation-delay: 1s;
  animation: fadeIn 2s;
  @include desktop-view {
    margin-top: -$double-margin-size;
  }
}

#work_with_us_slider {
  @include desktop-view {
    left: 50%;
    margin-left: -($desktop-container / 2);
  }
  @include tablet-view {
    @include relative-left;
    width: 100%;
    padding-left: $triple-margin-size;
    margin-top: $double-margin-size;
  }
  div.slick-slide {
    animation-delay: 1s;
    animation: slide 2s;
    background-color: white;
    margin-right: $margin-size;
    border-radius: 3px;
    &:focus {
      outline: 0;
    }
    img {
      border-radius: 3px;
      @include desktop-and-tablet-view {
        width: 534px;
      }
      @include mobile-view {
        width: 100vw;
      }
    }
  }
}

//
//	Section content
//  --------------------
//

div.intro {
  @include relative-left;
  @include desktop-view {
    width: 75%;
    clear: both;
  }
}

a.permanent_form {
  @include relative-left;
  clear: both;
  @include button-style-border;
  margin-bottom: $triple-margin-size;
}

section.content h2 {
  @include relative-left;
  clear: both;
  @include link-style();
  color: $text-color;
  margin-bottom: $double-margin-size;
  padding-bottom: $half-margin-size;
  border-bottom: 1px dotted $highlight-color;
  @include desktop-view {
    width: 75%;
  }
 @include tablet-and-mobile-view {
   width: 100%;
 }
}

div.job_offer {
  @include relative-left;
  margin-bottom: $double-margin-size;
  padding-bottom: $double-margin-size;
  border-bottom: 1px dotted $highlight-color;
  @include desktop-view {
    width: 75%;
    clear: both;
  }
  @include tablet-and-mobile-view {
    width: 100%;
  }
}

div.job_offer > h3 {
  margin-bottom: $half-margin-size;
  > a {
    @include heading-4-text-style();
    @include link-underline-hover-effect();
    br {
      display: none;
    }
  }
}

div.job_offer > p.delegation {
  @include small-p-text-style($highlight-color);
  font-weight: bold;
  margin-bottom: 0;
}

div.job_offer div.details {
  @include relative-left;
  width: 100%;
  margin-top: $margin-size;
}

div.job_offer div.action_buttons {
  @include relative-left;
  margin-top: $margin-size;
  width: 100%;
  display: flex;
  @include desktop-view {
    align-items: center;
  }
  @include tablet-and-mobile-view {
    flex-direction: column;
    align-items: baseline;
  }
  a.toggle_details {
    display: inline-block;
    @include relative-left;
    @include link-style();
    margin-right: $double-margin-size;
    @include tablet-and-mobile-view {
      margin-bottom: $margin-size;
    }
    span {
      &.show {
        display: inline-block;
      }
      &.hide {
        display: none;
      }
      > i {
        margin-left: $half-margin-size;
      }
    }
    &.hide span {
      &.show {
        display: none;
      }
      &.hide {
        display: inline-block;
      }
    }

  }
  a.cv_form {
    @include relative-left;
    clear: both;
    @include button-style-fill;
  }
}

div.related_legal_info {
  @include relative-left;
  clear: both;
  > p {
    @include small-p-text-style($pale-text-color);
    @include line-height(16);
    margin-bottom: $half-margin-size;
    > img {
      margin-top: $half-margin-size;
    }
  }
  @include desktop-view {
    width: 75%;
    clear: both;
  }
  @include tablet-and-mobile-view {
    width: 100%;
  }
}

}//work_with_us_page