//
//	NEWS
//	####################################################################################################
//
	// Inside here (quick view):
  // - Header
  // - Section content

#news_page {

//
//	Header
//  --------------------
//

header.standard {
  background-image: url('https://res.cloudinary.com/fridama/image/upload/c_scale,e_grayscale,w_1920/v1553599342/080.jpg');
  background-position: bottom center;
  height: initial;
  ul.breadcrumb {
    margin-top: $desktop-nav-height + $margin-size;
  }
  h1 {
    margin-top: $margin-size;
    margin-bottom: $margin-size;
  }
  p.news_date {
    margin-bottom: $double-margin-size;
    @include small-p-text-style($countertype-pale-text-color);
    font-weight: bold;
  }
}

//
//	Section content
//  --------------------
//

section.content {
  div.bounded_content {
  }
  article {
    @include relative-left;
    margin-bottom: $double-margin-size;
    @include desktop-view {
      width: 75%;
      clear: both;
    }
    @include tablet-and-mobile-view {
      width: 100%;
    }
  }
  a.back {
    @include relative-left;
    clear: both;
    @include link-style();
    > i {
      margin-right: $half-margin-size;
    }
  }
}

}//news_page
