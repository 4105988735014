//
//	Animations
//	####################################################################################################
//
	// Inside here (quick view):
  // - Fromtop
  // - Fromleft
  // - Fromright
  // - FadeIn
  // - Scaled
  // - Slide

//
//	Fromtop
//  --------------------
//

  @include keyframes(fromtop) {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

//
//	Frombottom
//  --------------------
//

  @include keyframes(frombottom) {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

//
//	Fromleft
//  --------------------
//

  @include keyframes(fromleft) {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

//
//	Fromright
//  --------------------
//

  @include keyframes(fromright) {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

//
//	FadeIn
//  --------------------
//

  @include keyframes(fadeIn) {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

//
//	Scaled
//  --------------------
//

  @include keyframes(scaled) {
    50% {
      transform: scale(1.3);
    }
  }

//
//	Slide
//  --------------------
//

@include keyframes(slide) {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}