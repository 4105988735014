//
//  BASE STRUCTURES (basic structure tools)
//  ####################################################################################################
//
  // Inside here (quick view):
  // - Mediaqueries

//
//	Mediaqueries
//  --------------------
//

@mixin desktop-view {
  @media only screen and (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin desktop-and-tablet-view {
  @media only screen and (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin tablet-and-mobile-view {
  @media only screen and (min-width: 0) and (max-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin tablet-view {
  @media only screen and (min-width: $tablet-breakpoint) and (max-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin mobile-view {
  @media only screen and (max-width: $mobile-breakpoint) {
    @content;
  }
}