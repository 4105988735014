//
//  GENERAL VARIABLES
//  ####################################################################################################
//
  // Inside here (quick view):
  // - Layout
  // - Sizes
  // - Color

//
//  Layout
//  --------------------
//

$margin-size: 24px;
$half-margin-size: $margin-size / 2;
$quarter-margin-size: $half-margin-size / 2;
$double-margin-size: $margin-size * 2;
$triple-margin-size: $margin-size * 3;

$mobile-container: 100%;
$tablet-container: 720px;
$desktop-container: 1160px;

$mobile-breakpoint: $tablet-container + ($margin-size - 1px);
$tablet-breakpoint: $tablet-container + $margin-size;
$desktop-breakpoint: $desktop-container;

//
//  Sizes
//  --------------------
//

$desktop-nav-height: 80px;
$mobile-nav-height: 80px;
$homepage-header-height: 650px;

//
//  Color
//  --------------------
//

$text-color: #555;
$pale-text-color: rgba($text-color, .8);
$countertype-text-color: #FFF;
$countertype-pale-text-color: rgba($countertype-text-color, .8);

$light-gray-color: #F9F9F9;
$medium-gray-color: rgba(54, 55, 73, 0.85);

$fridama-blue-light:#00ADEE;
$fridama-blue-medium: #0071BB;
$fridama-blue-dark: #2E3191;
$lighten-fridama-blue-dark: lighten($fridama-blue-light, 30%);

$highlight-color: $fridama-blue-dark;