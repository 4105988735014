//
//  COMPONENTS
//  ####################################################################################################
//
  // Inside here (quick view):
  // - Materialize tabs

//
//	Materialize tabs
//  --------------------
//

div.materialize_tabs_wrapper {
  @include relative-left;
  width: 100%;
  ul.tabs {
    @extend .bounded_content;
  }
}