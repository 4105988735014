//
//	BASIC MIXINS & CLASSES (basic style tools) --> NOT MODIFY THIS FILE!
//	####################################################################################################
//
	// Inside here (quick view):
	// - Typography
	// - Position
	// - Appearance

//
//	Typography
//	--------------------
//

@mixin font-size($size) {
  font-size: $size + px;
  font-size: ($size/16) + rem; //body base font 16px
}
@mixin line-height($size) {
  line-height: $size + px;
  line-height: ($size/16) + rem;
}

//
//	Position
//	--------------------
//

@mixin relative-left {
	position: relative;
	float: left;
}
@mixin relative-right {
	position: relative;
	float: right;
}
@mixin basic-horizontal-center {
	margin-left: auto;
	margin-right: auto;
}
@mixin advanced-horizontal-center($width){
	left: 50%;
	margin-left: (-$width) / 2;
}
@mixin vertical-center($height){
	top: 50%;
	margin-top: (-$height) / 2;
}
@mixin clearfix() {
  &:after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
}
@mixin calc($property, $expression) {
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

//
//	Appearance
//	--------------------
//

@mixin square-box($side-width) {
	width: $side-width;
	height: $side-width;
}
@mixin circle-box($diameter) {
	width: $diameter;
	height: $diameter;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
}
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
@mixin gradient($startColor, $endColor) {
	background-color: $startColor;
	background: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
	background: -webkit-linear-gradient(top, $startColor, $endColor);
	background: -moz-linear-gradient(top, $startColor, $endColor);
	background: -ms-linear-gradient(top, $startColor, $endColor);
	background: -o-linear-gradient(top, $startColor, $endColor);
}