//
//	IMPORT VENDOR STYLESHEETS
//	####################################################################################################
//

// Materialize imports

@import "../vendor/materialize/color-variables";
@import "../vendor/materialize/color-classes";
@import "../vendor/materialize/variables";
// Reset
// @import "../vendor/materialize/normalize";

// Components
// @import "../vendor/materialize/global";
// @import "../vendor/materialize/badges";
// @import "../vendor/materialize/icons-material-design";
// @import "../vendor/materialize/grid";
// @import "../vendor/materialize/navbar";
// @import "../vendor/materialize/typography";
// @import "../vendor/materialize/transitions";
// @import "../vendor/materialize/cards";
// @import "../vendor/materialize/toast";
@import "../vendor/materialize/tabs";
// @import "../vendor/materialize/tooltip";
// @import "../vendor/materialize/buttons";
@import "../vendor/materialize/dropdown";
// @import "../vendor/materialize/waves";
// @import "../vendor/materialize/modal";
// @import "../vendor/materialize/collapsible";
// @import "../vendor/materialize/chips";
// @import "../vendor/materialize/materialbox";
// @import "../vendor/materialize/forms/forms";
// @import "../vendor/materialize/table_of_contents";
// @import "../vendor/materialize/sidenav";
// @import "../vendor/materialize/preloader";
// @import "../vendor/materialize/slider";
// @import "../vendor/materialize/carousel";
// @import "../vendor/materialize/tapTarget";
// @import "../vendor/materialize/pulse";
// @import "../vendor/materialize/datepicker";
// @import "../vendor/materialize/timepicker";

//
//	VENDOR CUSTOM (overwrite vendor styles)
//	#################################################################################################### 
//
	// Inside here (quick view):
	// - Materialize tabs
	// - Materialize select

//
//	Materialize tabs
//  --------------------
//

body {
	.tabs {
		background-color: transparent;
		border-bottom: 1px solid $highlight-color;
	}
	.tabs .tab a {
		@extend .link;
		opacity: .7;
	}
	.tabs .tab a:hover,
	.tabs .tab a.active,
	.tabs .tab a:focus,
	.tabs .tab a:focus.active {
		color: $highlight-color;
		opacity: 1;
		background-color: rgba($highlight-color, .1);
	}
	.tabs .indicator {
		background-color: $highlight-color;
	}
}

//
//	Materialize select
//  --------------------
//

// ul.select-dropdown {
// 	border: 1px solid $input-border-color;
// }